<template>
	<div>
		<s-text
			:label="label"
			clearable
			@keyupEnter="keyEnterPress"
			v-model="CodSenasa"
		>
		<template v-slot:append=''>
		  <v-icon style='cursor:pointer !important; ' class="mt-1" @click='showFarmLot=true'> mdi-magnify</v-icon> 
		</template>
</s-text>
		<v-dialog v-if=showFarmLot v-model="showFarmLot">
		<v-card>
		<FarmLot @dblClick='dblclick($event)' :FltLevel=1></FarmLot>
		</v-card>
		
		</v-dialog>
<v-dialog v-if=dialog v-model="dialog" width='80%' >
	<v-card>
				<s-toolbar  dark
					color="primary"
					close :label="'Código de Senasa   '+CodSenasa"
					@close="close"
				>
				</s-toolbar>
				<v-col lg="12">
					<v-data-table
						dense
						@dblclick:row="dblclickSenasa"
						:items="itemsLots"
						:headers="config.headers"
					>

					</v-data-table>
				</v-col>
			</v-card>
</v-dialog>

	</div>
</template>

<script>
	import _sFarm from "@/services/Technicalassistance/AgriculturalFarmsService";
	import FarmLot from "@/views/TechnicalAssistance/Certification/FarmLot";
	export default {
		components:{FarmLot},
		props: {
			label: {
				type: String,
				default: "Cod Senasa"
			},
			FagIDg: {
				type: Number,
				default: 0
			}
		},

		data() {
			return {
				showFarmLot:false,
				CodSenasa: "",
				dialog: false,
				config: {
					model: {
						FltID: "ID",
						FltName: "string",
						PrdID: "int",
						PrdText: "string"
					},
					
					headers: [
						{text: "ID", value: "FltID", sortable: true},
						{text: "Nombre Lote", value: "FltName",},
						{text: "Fundo", value: "FagName",},
						{text: "Nombre Productor", value: "PrdText", sortable: true,},
						{text: "Tipo Cultivo", value: "TypeCropText", sortable: true,},
						{text: "Cultivo", value: "TypeCultiveText", sortable: true,},
						{text: "Variedad", value: "VrtDescription", sortable: true,},
					],
				},
				itemsLots: [],
			}
		},

		watch: {
			'CodSenasa'() {
				this.$emit("searchSenasa",null);
			}
		},

		methods: {
			keyEnterPress() {
				if(this.CodSenasa.trim()==''){ 
					this.dialog=true;
				}
					let ObjSenasa = {
						CodSenasa: this.CodSenasa,
						FagID: this.FagIDg
					}
					
					_sFarm
					.listFarm(ObjSenasa, this.$fun.getUserID())
					.then((response) => {
						if (response.data != null) {
							this.itemsLots = response.data; 
							if(response.data.length == 1){
							this.$emit('searchSenasa', response.data[0]); }
							else if(response.data.length>1){
							this.dialog=true;
							}
							else this.$emit('searchSenasa', null); 
						}
					});
				
				
			},

			close()
			{
				this.dialog = false;
			},

			dblclick(item)
			{
				
item.VrtDescription=item.VrtIDText;
item.TypeCultiveText=item.CultiveText;
				  item.PrdText=item.PrdCardName;
				this.$emit('searchSenasa', item);
				this.showFarmLot = false;
			},
			dblclickSenasa(event, {item})
			{
				
				this.$emit('searchSenasa', item);
				this.dialog = false;
			}
		},
	}
</script>