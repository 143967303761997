<template>
  <div>
    <s-crud
      title="Lotes"
      @rowSelected="rowSelected($event)"
      :config="configCrud"
      @save="save($event)"
      :filter="filter"
      search-input
      @dblClick="dblclick($event)"
      ref="crudLtos"
    >
      <template slot-scope="props">
        <v-container class="pb-0">
          <v-row justify="center">
            <v-col cols="2" class="s-col-form">
              <s-text v-model="props.item.FltCode" label="Código"/>
            </v-col>

            <v-col cols="5" class="s-col-form">
              <s-text v-model="props.item.FltName" label="Nombre del Lote" />
            </v-col>
            <v-col cols="2" class="s-col-form">
              <s-select-definition v-model="props.item.FltLevel" :def="1159" label="Nivel"></s-select-definition>
            </v-col>
            
            <v-col cols="1" class="s-col-form"> <s-text v-model="props.item.FltHectares" label="Hectareas" /> </v-col>

            <v-col cols="2" class="s-col-form">
              <s-text v-model="props.item.FltVolume" label="Volumen" />
            </v-col>
            <v-col cols="3" class="s-col-form">
              <s-text v-model="props.item.FltObservation" label="Observación" />
            </v-col>

            <v-col cols="2" class="s-col-form">
              <s-select-definition v-model="props.item.FltStatus" :def="1152" label="Estado"></s-select-definition>
            </v-col>
          </v-row>
        </v-container>

      </template>

      <template v-slot:FltApproved="{ row }">
        <v-btn
          v-on="on"
          text
          black 
          @click="savePreApprovedLots()"
          :color="row.FltApproved == 1 ? 'success' : 'error'"
          small
        >
          <i class="fas fa-thumbs-up"></i>
        </v-btn>
      </template>

      <template v-slot:TypeCrop="{ row }">
        <v-simple-checkbox hide-details="" disabled="" style="margin:0px;padding:0px;" :value="row.TypeCrop == 1 ? true : false">
        </v-simple-checkbox>
      </template>

      <template v-slot:FltStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.FltStatus == 1 ? 'success' : 'error'">
          {{ row.FltStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>

      <template v-slot:FltLevel="{ row }">
       <v-chip style="margin:0px" x-small :color="row.FltLevel == 1 ? 'success' :row.FltLevel == 2? 'warning':'default'">
          {{ row.FltLevel == 1 ? "Lote" : row.FltLevel == 2?"SubLote":"Lote Padre" }}
        </v-chip>
      </template>

      <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
          {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>

    </s-crud>
  </div>
</template>


<script> 
import _sFarmLot from "@/services/Technicalassistance/FarmLotService";
import SText from '../../../components/Utils/SText.vue'; 
export default { 
  components: {SText},
  props: {
    parentID: { type: Number, default: 0 },
    FltLevel:{type:Number,default:null}
  },

  data: () => ({
    preApprovedLots: [],
    isAdmin:true,
    filter: { },
    lotsSelected: [],
    selected: {}
  }),

  computed: {  
    configCrud() {
      return {
        model: {
          FagID: "int",
          FltID: "ID",
          FltName: "string",
          TypeCrop: "int",
          TypeVariety: "int",          
          FltVolume: "int",
          FltObservation: "string",
          FltLevel: "int",
          SecStatus: "int",
          FltStatus: "int",
          CodSenasa:'string',
          FltApproved: ""
        },
        service: _sFarmLot,
        headers: this.isAdmin
          ? [ 
              /* { text: "Aprobar", value: "FltApproved", sortable: false }, */
              { text: "Código", value: "FltID", sortable: false },
              { text: "Nombre Lote", value: "FltName", sortable: false },
              { text: "Fundo", value: "FagName", sortable: false },
              { text: "Productor", value: "PrdCardName", sortable: false },
              { text: "Tipo Proveedor", value: "TypeSupplierName", sortable: false },
              { text: "Tipo de Fruta", value: "TypeCropText", sortable: false },
              { text: "Cultivo", value: "CultiveText", sortable: false },
              { text: "Nivel", value: "FltLevel", sortable: false },
               { text: "Variedad", value: "VrtIDText", sortable: false },
              { text: "Cod. Senasa", value: "FltCodSenasa", sortable: false },
              { text: "Zona", value: "ZonIDText", sortable: false },
              { text: "SubZona. Senasa", value: "SzoIDText", sortable: false },
            ]
          : [
               { text: "Código", value: "FltID", sortable: false },
              { text: "Nombre Lote", value: "FltName", sortable: false },
              { text: "Fundo", value: "FagName", sortable: false },
              { text: "Productor", value: "PrdCardName", sortable: false },
              { text: "Tipo Proveedor", value: "TypeSupplierName", sortable: false },
              { text: "Tipo de Fruta", value: "TypeCropText", sortable: false },
              { text: "Cultivo", value: "CultiveText", sortable: false },
              { text: "Nivel", value: "FltLevel", sortable: false },
               { text: "Variedad", value: "VrtIDText", sortable: false },
              { text: "Cod. Senasa", value: "FltCodSenasa", sortable: false },
              { text: "Zona", value: "ZonIDText", sortable: false },
              { text: "SubZona. Senasa", value: "SzoIDText", sortable: false },
            ],
      };
    },
  },
  methods: {
    addPreApprovedLots(items)
    {

    },

    savePreApprovedLots()
    {
      this.selected.FltStatus = 10;
      this.selected.FltApproved == 1 ? this.selected.FltApproved = 0 : this.selected.FltApproved = 1
      this.selected.UsrUpdateID = this.$fun.getUserID();
      _sFarmLot
      .save(this.selected, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200)
        {
          let message = ''
          let color = ''
          this.selected.FltApproved == 1 ? message = 'Aprobado Correctamente' : message = 'Desaprobado Correctamente'
          this.$fun.alert(message, "success");
          this.$refs.crudLtos.refresh();
        }
        
      }) 
    },

    dblclick(item){
      this.$emit('dblClick',item);
   
    },

    Initialize() {
      this.filter = { FagID: -1 ,FltLevel: this.FltLevel};
    },

    rowSelected(rows) {
      if (rows.length > 0) {
        this.$emit('rowSelectFarmLot', rows[0].FltID);
        this.selected = rows[0];
      }
    },

    save(item) {
      if (item.FltName.length == 0) {
        this.$fun.alert("Registre Nombre del Lote", "warning");
        return;
      }
      if (item.TypeCondition == 0) {
        this.$fun.alert("Seleccione Tipo de Fruta", "warning");
        return;
      }
      if (item.TypeCondition == 0) {
      this.$fun.alert("Seleccione Tipo de Fruta", "warning");
      return;  

      }
      if (item.TypeCondition == 2 && item.FagCodeOrganic == null) {
        this.$fun.alert("Ingrese código Organico", "warning");
        return;
      }
      
      item.save();
    },
  },
  watch: {
    parentID() {
      this.Initialize();
    },
  },
  created() {
    this.filter = { FagID: -1,FltLevel:this.FltLevel };
  },
};
</script>
